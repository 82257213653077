<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="1400px"
        fullscreen
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer> </v-spacer>
            <!-- <span>
              {{ "Atelier : " + (bt.gamme_caption ? bt.gamme_caption : "") }}
            </span>
            <v-spacer> </v-spacer> -->
            <v-autocomplete
              v-model="editedItem.machine_id"
              :item-text="'label'"
              :item-value="'id'"
              :items="machines"
              label="Machine"
              :rules="[(v) => !!v || 'Machine obligatoire']"
              @change="machine_change"
              v-if="editedItem.id < 0"
              dense
            ></v-autocomplete>
            <span v-else>
              {{ "Machine : " + machine.label }}
            </span>
            <v-spacer> </v-spacer>
            <v-layout row justify-center align-center v-if="progress">
              <v-progress-linear
                indeterminate
                :size="30"
                :width="7"
                color="purple"
              ></v-progress-linear>
            </v-layout>
            <v-btn
              color="blue darken-1"
              @click="valider"
              v-if="editedItem.statut_id == '18' && editedItem.id > 0"
              small
            >
              Clotûrer
            </v-btn>
            <v-btn
              color="blue darken-1"
              @click="save(18)"
              v-if="editedItem.statut_id == '18'"
              class="mx-2"
              small
              :disabled="change == 0"
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" @click.stop="close" small>
              Fermer
            </v-btn>
          </v-card-title>
          <v-card-subtitle>
            <v-row>
              <v-col cols="12" sm="1" md="2" class="mt-4">
                Date Fiche : {{ datefr(editedItem.fiche_date) }}
              </v-col>
              <v-col cols="12" sm="6" md="6" v-if="machine.sp == 0">
                <v-autocomplete
                  v-model="editedItem.bt_id"
                  :item-text="
                    (item) =>
                      (item.no_bt ? item.no_bt + ' - ' : '') +
                      (item.no_order ? item.no_order + ' - ' : '') +
                      (item.label ? item.label + ' - ' : '') +
                      (item.gamme_caption ? item.gamme_caption : '')
                  "
                  :item-value="'id'"
                  :items="bts_list_machine"
                  label="Bon de Travail"
                  :rules="[(v) => !!v || 'Bon de Travail obligatoire']"
                  @change="bt_change"
                  v-if="editedItem.id < 0"
                  dense
                ></v-autocomplete>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
                v-if="machine.sp == 1 && editedItem.id < 0"
              >
                <v-autocomplete
                  v-model="editedItem.article_id"
                  :item-text="'label'"
                  :item-value="'id'"
                  :items="machine_articles_livs"
                  label="Produit"
                  @change="article_change"
                  :rules="[(v) => !!v || 'Produit obligatoire']"
                  dense
                >
                  <template v-slot:selection="{ item }">
                    <!-- HTML that describe how select should render selected items -->
                    {{ (item.code ? item.code + "-" : "") + item.label }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="'Produit : ' + data.item.product_label"
                      ></v-list-item-title>
                      <v-list-item-title
                        v-html="'Article : ' + data.item.label"
                      ></v-list-item-title>
                      <v-list-item-title
                        v-html="'Fournisseur : ' + data.item.tier_name"
                      ></v-list-item-title>
                      <v-list-item-title
                        v-html="'Code : ' + data.item.code"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="1" md="2">
                <v-select
                  ref="agents"
                  :items="agents"
                  v-model="editedItem.agent_id"
                  item-text="nom"
                  :item-value="machine.sp == 1 ? 'id' : 'agent_id'"
                  label="Operateur"
                  :readonly="readonly"
                  :rules="[(v) => !!v || 'Operateur obligatoire']"
                  dense
                  @change="change++"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="1" md="2">
                <v-select
                  ref="shifts"
                  :items="shifts"
                  v-model="editedItem.shift_id"
                  item-text="label"
                  item-value="id"
                  label="Poste de Travail"
                  :readonly="readonly"
                  :rules="[(v) => !!v || 'Poste de Travail obligatoire']"
                  dense
                  @change="change++"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="6" md="12" v-if="editedItem.bt_id">
                  <v-container fluid>
                    <v-card>
                      <v-card-title>
                        {{ "BT N°: " + bt.no_bt }}
                        <v-spacer></v-spacer>
                        {{ "OF N°: " + order.no_order }}
                        <v-spacer></v-spacer>
                        {{
                          order.nocmd
                            ? "Commande : " +
                              order.nocmd +
                              "-" +
                              order.cmd_label
                            : ""
                        }}
                      </v-card-title>
                      <v-card-subtitle>
                        {{
                          order.tier_name ? "Client : " + order.tier_name : ""
                        }}
                        <v-spacer></v-spacer>
                        {{ "Produit : " + order.label }}
                      </v-card-subtitle>
                    </v-card>
                  </v-container>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="machine.sp == 1">
                  <v-card>
                    <v-card-title>
                      {{ "Produit : " + product_in.product_label }}
                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  v-if="editedItem.bt_id"
                  class="mt-2"
                >
                  {{ lib_lance }}
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  v-if="editedItem.bt_id"
                  class="mt-2"
                >
                  {{ lib_fab }}
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  v-if="editedItem.bt_id"
                  class="mt-2"
                >
                  {{ lib_rest }}
                </v-col>

                <v-container fluid>
                  <v-row>
                    <v-tabs v-model="tabs" class="my-tabs">
                      <v-tab> Production </v-tab>
                      <v-tab> Arrêts </v-tab>
                    </v-tabs>
                    <v-col cols="12" sm="6" md="6">
                      <listitems
                        :list="input_list"
                        :qUpdate="Qintrantupdate"
                        :Get_suf="'FicheIntrant'"
                        :headers="inputheaders"
                        :title="'Intrants'"
                        :master="false"
                        :add="false"
                        :del="false"
                        :Update="editedItem.statut_id == '18'"
                        :showstd="true"
                        :key="frml2"
                        :ipg="5"
                        :exp_excel="false"
                        :print="false"
                        @open="OpenInputForm"
                        v-if="tabs == '0'"
                      >
                      </listitems>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" v-if="editedItem.id > 0">
                      <listitems
                        :list="output_list"
                        :headers="outputheaders"
                        :title="'Production'"
                        :master="true"
                        :add="editedItem.statut_id == '18'"
                        :del="editedItem.statut_id == '18'"
                        :add_disable="detail_load && !add_disable"
                        :del_disable="output.index != 0"
                        :Update="false"
                        :showedit="false"
                        :Total="true"
                        :print="false"
                        :exp_excel="false"
                        @open="OpenOutputForm"
                        @rowselect="OutputSelect"
                        :key="frml"
                        :laoding="listload"
                        :ipg="5"
                        @delete="delete_item"
                        v-if="tabs == '0'"
                      >
                      </listitems>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" v-if="editedItem.id > 0">
                      <listitems
                        :list="arret_list"
                        :headers="arretheaders"
                        :qCreate="Qarretcreate"
                        :qUpdate="Qarretupdate"
                        :qDelete="Qarretdelete"
                        :Get_suf="'FicheArret'"
                        :title="'Arrets'"
                        :master="false"
                        :add="editedItem.statut_id == '18'"
                        :del="editedItem.statut_id == '18'"
                        :Update="editedItem.statut_id == '18'"
                        :showedit="true"
                        :print="false"
                        :Total="true"
                        :exp_excel="false"
                        :showstd="true"
                        :key="frml3"
                        :laoding="listload"
                        :ipg="5"
                        v-if="tabs == '1'"
                      >
                      </listitems>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" v-if="editedItem.id > 0">
                      <listitems
                        :list="dechet_list"
                        :headers="dechetheaders"
                        :qCreate="Qdechetcreate"
                        :qUpdate="Qdechetupdate"
                        :qDelete="Qdechetdelete"
                        :Get_suf="'FicheDechet'"
                        :title="'Dechets'"
                        :master="false"
                        :add="editedItem.statut_id == '18'"
                        :del="editedItem.statut_id == '18'"
                        :Update="editedItem.statut_id == '18'"
                        :showedit="true"
                        :print="false"
                        :Total="true"
                        :exp_excel="false"
                        :showstd="true"
                        :key="frml4"
                        :laoding="listload"
                        :ipg="5"
                        v-if="tabs == '1'"
                      >
                      </listitems>
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-alert v-model="alert" :type="type_alert" dismissible>{{
              message
            }}</v-alert>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <ficheoutput
      :item="output"
      :items="output_list"
      :fiche="editedItem"
      :bt="bt"
      :product_in="product_in"
      :machine="machine"
      :num_seq="num_seq"
      :showForm="!isFOClose"
      :attributes="attributes"
      :bom_list="bom"
      :input_list="input_list"
      :key="kfo"
      :code="code"
      @close="isFOClose = true"
      @save="save_output"
    >
    </ficheoutput>
    <formlist
      :title="title"
      :list="input_detail"
      :showform="!isListClose"
      :listheaders="colisheaders"
      :key="kl"
      :Total="true"
      @close="isListClose = true"
      width="1000px"
    >
    </formlist>
    <confirmdialog ref="confirm" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
function paddy(num, padlen, padchar) {
  var pad_char = typeof padchar !== "undefined" ? padchar : "0";
  var pad = new Array(1 + padlen).join(pad_char);
  return (pad + num).slice(-pad.length);
}
function countString(str, letter, pos) {
  let count = 0;
  let lastpos = -1;
  // looping through the items
  for (let i = pos; i < str.length; i++) {
    // check if the character is at that position
    if (str.charAt(i) == letter) {
      if (lastpos == -1 || lastpos + 1 == i) {
        count += 1;
        lastpos = i;
      }
    }
  }
  return count;
}

import CREATE_FICHE from "../graphql/Production/CREATE_FICHE.gql";
import UPDATE_FICHE from "../graphql/Production/UPDATE_FICHE.gql";
import INSERT_FICHE_INPUT from "../graphql/Production/INSERT_FICHE_INPUT.gql";
import UPDATE_FICHE_INTRANT from "../graphql/Production/UPDATE_FICHE_INTRANT.gql";

import CREATE_FICHE_ARRET from "../graphql/Production/CREATE_FICHE_ARRET.gql";
import UPDATE_FICHE_ARRET from "../graphql/Production/UPDATE_FICHE_ARRET.gql";
import DELETE_FICHE_ARRET from "../graphql/Production/DELETE_FICHE_ARRET.gql";

import INSERT_FICHE_DETAIL from "../graphql/Production/INSERT_FICHE_DETAIL.gql";

import CREATE_FICHE_DECHET from "../graphql/Production/CREATE_FICHE_DECHET.gql";
import UPDATE_FICHE_DECHET from "../graphql/Production/UPDATE_FICHE_DECHET.gql";
import DELETE_FICHE_DECHET from "../graphql/Production/DELETE_FICHE_DECHET.gql";

import PRODUCT_LIST_INPUT from "../graphql/Product/PRODUCT_LIST_INPUT.gql";
import PRODUCTION_LIST from "../graphql/Production/PRODUCTION_LIST.gql";
import ARTICLES_LIVS_MACHINE from "../graphql/Mouvment/ARTICLES_LIVS_MACHINE.gql";

export default {
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    listitems: () => import("./ListItems.vue"),
    ficheoutput: () => import("./FicheOutput.vue"),
    formlist: () => import("../components/FormList.vue"),
  },
  name: "ficheprod",
  props: {
    item: Object,
    items: Array,
    open_bts: Array,
    shifts: Array,
    arrets: Array,
    dechets: Array,
    machines: Array,
    agents_list: Array,
    showForm: Boolean,
    readonly: Boolean,
    attributes: Array,
  },
  data: () => ({
    tabs: "0",
    change: 0,
    code: "",
    glist: false,
    imp: false,
    title: "Liste Produit",
    listload: false,
    list: false,
    progress: false,
    isFOClose: true,
    isListClose: true,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    defaultItem: {
      id: -1,
      fiche_date: null,
      statut_name: "Brouillon",
      statut_id: "18",
      details: [],
    },
    editedItem: {},
    intrant: {},
    qte: 0,
    tol: 0,
    bt: {},
    order: {},
    output: {},
    detail_load: false,
    inputheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Code",
        value: "code",
        slot: "href",
        selected: true,
      },
      {
        text: "Article",
        value: "label",
        selected: true,
      },
      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "cur",
        align: "end",
        selected: true,
        edit: true,
        type: "qte",
        dec: 2,
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Quantité obligatoire"],
      },
      // {
      //   text: "Cons.Th",
      //   value: "cons_th",
      //   slot: "cur",
      //   align: "end",
      //   selected: true,
      // },
      {
        text: "Unité",
        value: "unit_name",
        selected: true,
      },
      {
        text: "Comment",
        value: "comment",
        selected: false,
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
      },
    ],
    outputheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Code",
        value: "code",
        selected: true,
        edit: true,
        slot: "href",
        type: "text",
        default: "",
        readonly: true,
        sm: 6,
        md: 6,
        rules: [(v) => !!v || "Code obligatoire"],
      },
      {
        text: "Produit",
        value: "label",
        selected: true,
        hiden: true,
      },
      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "cur",
        align: "end",
        selected: true,
        edit: true,
        type: "qte",
        dec: 2,
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Quantité obligatoire"],
      },
      {
        text: "Unité",
        value: "unit",
        selected: true,
        hiden: true,
      },
      {
        text: "Comment",
        value: "comment",
        selected: false,
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
      },
    ],
    arretheaders: [
      {
        text: "No",
        value: "no",
        totlib: "true",
        selected: true,
      },
      {
        text: "Type",
        value: "type_id",
        selected: true,
        slot: "simple-select",
        item_value: "id",
        item_text: "label",
        show_list: [],
      },
      {
        text: "Type Arret",
        value: "type_id",
        hiden: true,
        edit: true,
        type: "select",
        item_value: "id",
        item_text: "label",
        select_list: [],
        default: "",
        sm: 4,
        md: 4,
        rules: [(v) => !!v || "Type Arret obligatoire"],
      },
      {
        text: "Temps(min)",
        value: "temps",
        slot: "cur",
        align: "end",
        total: "temps",
        selected: true,
        edit: true,
        type: "qte",
        dec: 2,
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Quantité obligatoire"],
      },
      {
        text: "Comment",
        value: "comment",
        selected: false,
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
      },
      {
        text: "fiche_id",
        value: "fiche_id",
        default: "",
        edit: true,
        hiden: true,
        selected: false,
      },
    ],
    dechetheaders: [
      {
        text: "No",
        value: "no",
        totlib: "true",
        selected: true,
      },
      {
        text: "Type",
        value: "type_id",
        selected: true,
        slot: "simple-select",
        item_value: "id",
        item_text: "label",
        show_list: [],
      },
      {
        text: "Type Dechet",
        value: "type_id",
        hiden: true,
        edit: true,
        type: "select",
        item_value: "id",
        item_text: "label",
        select_list: [],
        default: "",
        sm: 4,
        md: 4,
        rules: [(v) => !!v || "Type Dechet obligatoire"],
      },
      {
        text: "Quantité",
        value: "qte",
        slot: "cur",
        align: "end",
        total: "qte",
        selected: true,
        edit: true,
        type: "qte",
        dec: 2,
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Quantité obligatoire"],
      },
      {
        text: "Comment",
        value: "comment",
        selected: false,
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
      },
      {
        text: "fiche_id",
        value: "fiche_id",
        default: "",
        edit: true,
        hiden: true,
        selected: false,
      },
    ],
    colisheaders: [],
    colisheaders2: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Code",
        value: "code",
        selected: true,
        slot: "chip",
        color: "color",
        edit: true,
        type: "text",
        sm: 4,
        md: 4,
      },
      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "cur",
        align: "end",
        selected: true,
        barre: true,
        column: "qte_rest",
        valeur: 0,
      },
      {
        text: "Qte Rest.",
        value: "qte_rest",
        total: "qte_rest",
        slot: "cur",
        align: "end",
        selected: true,
      },
      {
        text: "% Rest.",
        value: "prc_rest",
        slot: "progress",
        selected: true,
      },
      {
        text: "Comment",
        value: "comment",
        selected: true,
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
      },
    ],
    output_list: [],
    input_list: [],
    arret_list: [],
    dechet_list: [],
    machine_articles_livs: [],
    agents: [],
    bom: [],
    frml: 600,
    frml2: 800,
    frml3: 900,
    frml4: 700,
    kl: 1900,
    kfo: 1000,
    input_detail: [],
    machine: {},
    product_in: {},
    alert: false,
    type_alert: "info",
    message: "",
    agent: {},
    num_seq: 1,
    add_disable: false,
  }),
  watch: {
    output_list: {
      handler() {
        if (this.bt.order) {
          this.add_disable =
            (this.bt.order.links.length == 0 &&
              this.bt.order.bylist == "0" &&
              this.output_list.length > 0) ||
            (this.bt.order.links.length > 0 &&
              this.order.links.findIndex((elm) => elm.bylist == "1") == -1 &&
              this.output_list.length == this.bt.order.links.length);
        }
        if (this.product_in.products_out) {
          this.add_disable =
            this.product_in.products_out.findIndex(
              (elm) => elm.bylist == "1"
            ) == -1 &&
            this.output_list.length == this.product_in.products_out.length;
        }
      },
    },
  },
  computed: {
    bts_list_machine() {
      let list = [];
      if (this.bts_list)
        list = this.bts_list.filter(
          (elm) => elm.machine_id == this.editedItem.machine_id
        );
      return list;
    },
    Qarretcreate() {
      return CREATE_FICHE_ARRET;
    },
    Qarretupdate() {
      return UPDATE_FICHE_ARRET;
    },
    Qarretdelete() {
      return DELETE_FICHE_ARRET;
    },

    Qdechetcreate() {
      return CREATE_FICHE_DECHET;
    },
    Qdechetupdate() {
      return UPDATE_FICHE_DECHET;
    },
    Qdechetdelete() {
      return DELETE_FICHE_DECHET;
    },
    Qintrantupdate() {
      return UPDATE_FICHE_INTRANT;
    },
    code_produit() {
      return this.$store.state.options[0].code_produit;
    },
    gammes() {
      return this.order.gammes ? this.order.gammes : [];
    },
    amalgame() {
      let ok = false;
      if (this.bt.tosell == "1" && this.bt.order.links.length > 0) ok = true;
      return ok;
    },
    isSimple() {
      let ok = false;
      if (this.product_in.produit_id) {
        ok = true;
      }
      return ok;
    },
    bts_list() {
      return this.open_bts;
    },

    qte_lance() {
      let q = 0;
      if (this.bt.id)
        if (this.bt.qte)
          q = parseFloat(this.bt.qte.toFixed(this.order.decimal));

      return q;
    },
    lib_lance() {
      let l =
        "Qte BT: " +
        this.numberWithSpace(this.qte_lance.toFixed(this.order.decimal)) +
        " " +
        (this.order.unit ? this.order.unit : "");
      return l;
    },

    qte_fab() {
      let f = 0;
      if (this.bt.id)
        if (this.bt.qte_fab)
          f = parseFloat(this.bt.qte_fab.toFixed(this.order.decimal));
      return f;
    },

    lib_fab() {
      let f =
        "Total " +
        (this.bt
          ? this.bt.product_statut
            ? this.bt.product_statut
            : "Fabriqué"
          : "") +
        ": " +
        this.numberWithSpace(this.qte_fab) +
        " " +
        (this.order.unit ? this.order.unit : "");
      return f;
    },

    qte_fab_br() {
      let q = 0;
      if (this.bt.id)
        q = this.bt.qte_fab_br
          ? parseFloat(this.bt.qte_fab_br.toFixed(this.order.decimal))
          : 0;

      return q;
    },
    qte_rest() {
      let q = 0;
      if (this.bt.id) {
        if (this.qte_lance - this.qte_fab > 0)
          q = this.qte_lance - this.qte_fab;
        q = parseFloat(q.toFixed(this.order.decimal));
      }
      return q;
    },

    lib_rest() {
      let r =
        "Reste : " +
        this.numberWithSpace(this.qte_rest) +
        " " +
        (this.order.unit ? this.order.unit : "");
      return r;
    },
    attributs_list() {
      return this.attributes.filter((elm) => elm.type_attr == "colis");
    },
    bylist() {
      let l = "0";
      if (this.order.id) l = this.order.bylist;
      else l = "1";
      return l;
    },
    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Fiche "
          : "Fiche N° " + this.editedItem.nofiche;
      else return "";
    },
  },

  created() {},
  async mounted() {
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        await this.machine_change();
        this.input_list = this.editedItem.intrants;

        if (this.editedItem.bt) {
          this.order = this.editedItem.bt.order;
          this.agents = this.editedItem.bt.agents;
          this.bt_change();
        } else {
          this.agents = this.agents_list;
        }
        this.frml2++;
        this.arret_list = this.editedItem.arrets;
        this.frml3++;
        this.dechet_list = this.editedItem.dechets;
        this.frml4++;
        this.list_fiche();
        this.arretheaders[5].default = this.editedItem.id;
        this.dechetheaders[5].default = this.editedItem.id;
      } else {
        this.defaultItem.fiche_date = this.$store.state.today;
        this.editedItem = Object.assign({}, this.defaultItem);
      }
    }
    this.arretheaders[1].show_list = this.arrets;
    this.arretheaders[2].select_list = this.arrets;
    this.dechetheaders[1].show_list = this.dechets;
    this.dechetheaders[2].select_list = this.dechets;
  },

  methods: {
    init_list() {
      this.colisheaders = [];
      this.colisheaders2.forEach((element) => {
        this.colisheaders.push(element);
      });
      if (this.machine.sp == "1") this.colisheaders[3].hiden = true;

      this.colisheaders[2].text = "Qte (" + this.intrant.unit_name + ")";
      this.colisheaders[2].dec = this.intrant.virgule;
      this.colisheaders[3].text = "Qte Rest. (" + this.intrant.unit_name + ")";
      this.colisheaders[3].dec = this.intrant.virgule;
      let colis_attributs = [];
      colis_attributs = this.intrant.ppattributs
        ? this.intrant.ppattributs.split(",")
        : [];

      for (let index = 0; index < colis_attributs.length; index++) {
        const element = colis_attributs[index];

        let i = this.attributs_list.findIndex((elm) => elm.id == element);
        if (i >= 0) {
          let ls = [];
          this.attributs_list[i].attributvalues.forEach((element) => {
            ls.push(element.name);
          });
          let elm = {
            text: this.attributs_list[i].caption
              ? this.attributs_list[i].caption
              : this.attributs_list[i].name,
            value: "ref" + index,
            selected: true,
            edit: true,
            key: this.attributs_list[i].key,
            type:
              this.attributs_list[i].attributvalues.length > 0
                ? "autocomplete"
                : "text",
            select_list: ls,
            show_list: ls,
            sm: 6,
            md: 6,
          };
          this.colisheaders.splice(index + 2, 0, elm);
        }
      }
    },
    async load_input_detail() {
      for (let index = 0; index < this.bom.length; index++) {
        const element = this.bom[index];
        let v = {
          produit_id: element.produit_id,
          of_id: this.order.id,
          machine_id: this.machine.sp == "1" ? this.machine.id : null,
          gamme_id: element.gamme_id,
          factor: this.product_in ? this.product_in.factor : null,
        };

        let r = await this.requette(
          element.produit_id ? PRODUCT_LIST_INPUT : PRODUCTION_LIST,
          v
        );
        if (r) {
          element.input_detail = element.produit_id
            ? r.product_list_input
            : r.production_list;
        }
      }
      this.detail_load = true;
    },
    datefr(date) {
      var result = new Date(date);
      return result.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    article_change() {
      this.bom = this.machine_articles_livs.filter(
        (elm) =>
          elm.bylist == "1" && elm.article_id == this.editedItem.article_id
      );

      let i = this.machine_articles_livs.findIndex(
        (elm) => elm.article_id == this.editedItem.article_id
      );
      if (i >= 0) {
        let j = this.machine.products_in.findIndex(
          (elm) => elm.produit_id == this.machine_articles_livs[i].produit_id
        );
        this.editedItem.tier_id = this.machine_articles_livs[i].tier_id;
        if (j >= 0) {
          this.product_in = this.machine.products_in[j];
          this.outputheaders[2].hiden = false;
          this.outputheaders[3].text = "Quantite";
          this.outputheaders[3].rules = [(v) => !!v || "Quantite obligatoire"];
        }
        this.load_articles();
        this.load_input_detail();
      }
    },
    async machine_change() {
      let i = this.machines.findIndex(
        (elm) => elm.id == this.editedItem.machine_id
      );
      if (i >= 0) {
        this.machine = this.machines[i];

        if (this.machine.sp == "0") {
          if (this.editedItem.id < 0) {
            if (this.bts_list_machine.length == 1) {
              this.editedItem.bt_id = this.bts_list_machine[0].id;
              this.bt_change();
            }
          }
        } else {
          this.outputheaders[2].hiden = false;
          this.outputheaders[4].hiden = false;
          this.agents = this.agents_list;
          let v = {
            machine_id: this.machine.id,
          };
          let r = await this.requette(ARTICLES_LIVS_MACHINE, v);
          if (r) {
            this.machine_articles_livs = r.articles_livres_machine.filter(
              (elm) =>
                this.machine.products_in
                  .filter((elm) => elm.products_out.length > 0)
                  .map((elm) => elm.produit_id)
                  .includes(elm.produit_id)
            );
            if (
              this.machine_articles_livs.length == 1 &&
              this.editedItem.id < 0
            ) {
              this.editedItem.article_id =
                this.machine_articles_livs[0].article_id;
              this.article_change();
            }
            if (this.editedItem.id > 0) this.article_change();
            if (this.item.codelist) {
              this.num_seq = parseInt(this.item.codelist) + 1;
            } else this.num_seq = 1;
            this.get_code();
          }
        }
      }
    },
    bt_change() {
      if (this.editedItem.id < 0) {
        let i = this.bts_list.findIndex(
          (elm) => elm.id == this.editedItem.bt_id
        );
        this.bt = {};
        if (i >= 0) {
          this.bt = this.bts_list[i];
          this.order = this.bts_list[i].order;
          this.agents = this.bts_list[i].agents;
        }
      } else {
        if (this.editedItem.bt) this.bt = this.editedItem.bt;
      }

      this.bom = this.bt.order.bom.filter(
        (elm) => elm.bylist == "1" && elm.gamme_id == this.bt.gamme_id
      );
      if (this.order.links.length == 0 || this.bt.tosell == "0") {
        this.outputheaders[3].text = this.order.unit_name
          ? this.order.unit_name
          : "Quantite";
        this.outputheaders[3].rules = [
          (v) =>
            !!v ||
            (this.order.unit_name ? this.order.unit_name : "Quantite") +
              " obligatoire",
        ];
      }

      this.outputheaders[2].hiden =
        this.order.links.length == 0 || this.bt.tosell == "0";
      this.outputheaders[4].hiden = this.outputheaders[2].hiden;

      if (this.item.codelist) {
        this.num_seq = parseInt(this.item.codelist) + 1;
      } else this.num_seq = 1;

      this.get_code();

      this.outputheaders[3].dec = this.order.decimal;

      this.load_articles();
      if (this.order.id) this.load_gamme();
      this.load_input_detail();
    },
    load_articles() {
      if (this.editedItem.id < 0) this.input_list = [];
      let list = [];
      list = this.order.id
        ? this.order.boms.filter((elm) => elm.gamme_id == this.bt.gamme_id)
        : this.machine_articles_livs.filter(
            (elm) => elm.article_id == this.editedItem.article_id
          );
      if (this.editedItem.id < 0 || !this.order.id) {
        list.forEach((elm2) => {
          this.input_list.push({
            id: elm2.id,
            of_id: this.order.id,
            article_id: elm2.article_id,
            code: elm2.code,
            label: elm2.label,
            unit_name: elm2.unit_name,
            ratio: this.order.id ? elm2.ratio : elm2.factor,
            qte_max: elm2.qte_rest,
            bylist: elm2.bylist,
            ppattributs: elm2.ppattributs,
            qte: 0,
            cons: 0,
            virgule: this.order.id ? elm2.virgule : elm2.decimal,
          });
        });
      } else {
        list.forEach((elm2) => {
          let j = this.input_list.findIndex(
            (elm3) => elm3.article_id == elm2.article_id
          );
          if (j >= 0) this.input_list[j].qte_max = elm2.qte_rest;
        });
      }
      this.frml2++;
    },
    async load_gamme() {
      let gamme = {};
      if (this.bt.id) {
        let i = this.gammes.findIndex(
          (elm) => elm.gamme_id == this.bt.gamme_id
        );
        if (i >= 0) {
          gamme = this.gammes[i];
          for (let index = 0; index < gamme.boms.length; index++) {
            const element = gamme.boms[index];
            if (element.gamme_id) {
              this.bom.push({
                id: element.id,
                of_id: this.order.id,
                code: element.code,
                unit: this.order.unit,
                label: element.label,
                ratio: element.qte,
                gamme_id: element.gamme_id,
              });

              i = this.gammes.findIndex(
                (elm) => elm.gamme_id == element.gamme_id
              );
              if (i >= 0) {
                let qmax_intr = this.gammes[i].qte_rest;
                if (this.editedItem.id < 0)
                  this.input_list.push({
                    id: element.id,
                    of_id: this.order.id,
                    gamme_id: element.gamme_id,
                    code: element.code,
                    label: element.label,
                    ratio: element.qte,
                    unit_name: this.order.unit,
                    qte_max: qmax_intr,
                    bylist: this.bylist,
                    qte: 0,
                    cons: 0,
                    virgule: this.order.decimal,
                  });
              }
            }
          }

          this.frml2++;
        }
      }
      this.frml++;
    },
    get_code() {
      let p = this.$store.state.options[0].code_produit;
      let nb_num = 0;
      let part_num = "9999";
      if (p) {
        let position = p.search("!");
        nb_num = countString(p, "9", position);
        part_num = p.substr(position + 1, nb_num);

        p = this.machine.code + "-" + part_num;
      } else p = this.machine.code + "-" + "9999";
      this.code = p.replace(part_num, paddy(this.num_seq, nb_num));
    },
    save_output(item) {
      if (this.amalgame) {
        item.forEach((element) => {
          this.output_list.unshift({
            id: element.id,
            code: element.code,
            label: element.label,
            qte: element.qte,
            unit: element.unit,
            amg_id: element.amg_id,
            parents: element.parents,
          });
        });
      } else this.output_list.unshift(item);

      this.num_seq = this.num_seq + (this.amalgame ? item.length : 1);
      this.get_code();

      this.frml2++;
      this.frml++;
      if (this.bt.order) {
        this.add_disable =
          (this.bt.order.links.length == 0 &&
            this.bt.order.bylist == "0" &&
            this.output_list.length > 0) ||
          (this.bt.order.links.length > 0 &&
            this.order.links.findIndex((elm) => elm.bylist == "1") == -1 &&
            this.output_list.length == this.bt.order.links.length);
      }
      if (this.product_in.products_out) {
        this.add_disable =
          this.product_in.products_out.findIndex((elm) => elm.bylist == "1") ==
            -1 &&
          this.output_list.length == this.product_in.products_out.length;
      }
      if (this.add_disable) this.isFOClose = true;
      else this.kfo++;
    },

    async OpenInputForm(item) {
      this.intrant = item;
      if (item.gamme_id || item.bylist == "1") {
        let v = {
          article_id: item.article_id,
          of_id: item.of_id,
          gamme_id: item.gamme_id,
          factor: this.product_in ? this.product_in.factor : null,
        };
        let r = await this.requette(
          item.gamme_id ? PRODUCTION_LIST : PRODUCT_LIST_INPUT,
          v
        );
        if (r) {
          item.input_detail = item.gamme_id
            ? r.production_list
            : r.product_list_input;
        }
      }
      this.input_detail = item.input_detail;
      this.title = this.intrant.label;
      this.init_list();
      this.isListClose = false;
      this.kl++;
    },
    async delete_item(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir supprimer cet element?"
        )
      ) {
        let l = [];

        item.parents.forEach((element) => {
          if (element.production_parent_id)
            l.push({
              production_parent_id: element.production_parent_id,
              qte: element.qte,
            });
        });
        let v = {
          create_uid: this.$store.state.me.id,
          fiche_id: this.item ? this.item.id : null,
          pfd_id: item.amg_id ? item.amg_id : item.id,
          parents: l,
        };
        let r = await this.maj(INSERT_FICHE_DETAIL, v);
        if (r) {
          this.output_list.splice(item.index, 1);
          if (this.amalgame) {
            let pfd_id = item.amg_id ? item.amg_id : item.id;
            let l = this.output_list.filter(
              (elm) => elm.id == pfd_id || elm.amg_id == pfd_id
            );
            if (l.length >= 0) {
              l.forEach((element) => {
                let i = this.output_list.findIndex(
                  (elm) => elm.id == element.id
                );
                if (i >= 0) this.output_list.splice(i, 1);
              });
            }
          }
          this.frml++;
          await this.load_input_detail();
        }
      }
    },
    OutputSelect(item) {
      this.output = item;
    },
    OpenOutputForm(item) {
      if (item.id < 0 && this.output_list.length > 0) {
        let last_parents =
          this.output_list[this.output_list.length - 1].parents;
        for (let index = 0; index < this.bom.length; index++) {
          const intrant = this.bom[index];
          let l;

          if (intrant.produit_id) {
            l = last_parents.filter((elm) => elm.fk_pob == intrant.id);
          }
          if (intrant.gamme_id) {
            l = last_parents.filter((elm) => elm.gamme_id == intrant.gamme_id);
          }
          intrant.input_detail.forEach((element) => {
            element.selected =
              l.map((elm) => elm.code).includes(element.code) &&
              element.qte_rest > 0;
          });
          intrant.current_id = intrant.input_detail
            .filter((elm) => elm.selected)
            .map((elm) => elm.id);
          intrant.currents = intrant.input_detail
            .filter((elm) => elm.selected)
            .map((elm) => elm.code);
        }
      }
      this.output = item;
      this.isFOClose = false;
      this.kfo++;
    },

    async insert_fiche_input() {
      let list = [];

      for (let index = 0; index < this.input_list.length; index++) {
        const element = this.input_list[index];

        let elm = {};
        elm.article_id = element.article_id;
        elm.gamme_id = element.gamme_id;
        elm.qte = element.qte;
        elm.comment = element.comment;
        list.push(elm);

        //
      }
      if (list.length > 0) {
        let v = {
          create_uid: this.$store.state.me.id,
          fiche_id: this.editedItem.id,
          list: list,
        };
        await this.maj(INSERT_FICHE_INPUT, v);
      }
    },

    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },

    async list_fiche() {
      this.output_list = [];
      if (this.editedItem.id >= 0) {
        this.listload = true;
        this.output_list = this.editedItem.details;
        this.listload = false;
        this.frml++;
      }
    },

    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change");
          }
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async requette(query, v) {
      this.progress = true;

      let r = [];

      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    async close() {
      if (this.output_list.length > 0 && this.item.id < 0) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir annuler"
          )
        )
          this.$emit("close", this.editedItem);
      } else this.$emit("close", this.editedItem);
    },
    async valider() {
      if (this.$refs.form.validate())
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir clotûrer cette fiche?"
          )
        ) {
          this.save(19);
        }
    },
    async save(statut) {
      this.alert = false;
      if (this.$refs.form.validate()) {
        let ok = true;
        if (ok) {
          this.editedItem.statut_id = statut;
          let v;
          if (this.editedItem.id > 0) {
            v = {
              fiche: {
                id: this.editedItem.id,
                gamme_id: this.bt ? this.bt.gamme_id : null,
                shift_id: this.editedItem.shift_id,
                agent_id: this.editedItem.agent_id,
                bt_id: this.editedItem.bt_id,
                machine_id: this.editedItem.machine_id,
                article_id: this.editedItem.article_id,
                statut_id: this.editedItem.statut_id,
                write_uid: this.$store.state.me.id,
              },
            };

            await this.maj(UPDATE_FICHE, v, true);
          } else {
            let v = {
              fiche: {
                gamme_id: this.bt ? this.bt.gamme_id : null,
                fiche_date: this.$store.state.today,
                of_id: this.bt ? this.bt.of_id : null,
                shift_id: this.editedItem.shift_id,
                agent_id: this.editedItem.agent_id,
                bt_id: this.editedItem.bt_id,
                machine_id: this.editedItem.machine_id,
                article_id: this.editedItem.article_id,
                statut_id: this.editedItem.statut_id,

                create_uid: this.$store.state.me.id,
                write_uid: this.$store.state.me.id,
              },
            };
            let r = await this.maj(CREATE_FICHE, v);
            if (r) {
              this.editedItem.id = v.createFiche.id;
              this.arretheaders[5].default = this.editedItem.id;
              this.dechetheaders[5].default = this.editedItem.id;
              await this.insert_fiche_input();

              this.$store.dispatch("Changed", true);
            }
          }
          this.$emit("refresh", this.editedItem);
          this.change = 0;
        }
      }
    },
  },
};
</script>
